.team-photo {
    margin: 20px auto 0;
    padding: 0;
    overflow: hidden;
    max-width: 150px;
    max-height: 150px;
    box-shadow: 0 16px 38px -12px rgba(30,126,52, 0.56), 0 4px 25px 0px rgba(30,126,52, 0.12), 0 8px 10px -5px rgba(30,126,52, 0.2);
    border-radius: 50%;
}

.team-photo img {
    width: 100%;
    height: auto;
}

.team-name {
    font-size: 18px;    
    margin-top: 15px;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: rgb(11, 161, 45);
}

.team-pos {
    font-size: 14px;
    color: #6c757d;
}
