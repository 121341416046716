.first_block {	
	width: 100%;
	background-image: url('../../img/headerBack.jpg');
	overflow: hidden;
	position: relative;
	z-index: 10;
	height: 100vh;	
	-webkit-background-size: cover;
	background-size: cover;	
}

.home-video-wrapper {	
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: -1;
}
    
.home-video-wrapper:before {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    content: "";
}
    
    .home-video-wrapper video {
        vertical-align: top;
        height: auto;
        width: auto;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }


    .header_block {	
        position: absolute;
        left: 0;
        width: 100%;
        top: 40%;		
    }
    
    .header_block h1 {	
        font-family: "Agency";
        font-size:20px;
        text-align: center;
        color: #c89b5b;
        text-transform:uppercase;
        letter-spacing:3px;
        text-shadow: 1px 1px 1px #FFF;
    }
    .header_block h2 {	
        font-family: "Agency";
        font-size:40px;
        text-align: center;
        color: #0c8e36;
        text-transform:uppercase;
        letter-spacing:3px;
        text-shadow: #ffffff 1px 1px 0, #ffffff -1px -1px 0, 
                     #ffffff -1px 1px 0, #ffffff 1px -1px 0;
    }
    
    .text_in_header {
        margin: 20px 250px;
        padding:5px; 
        color:#fff;
        font-size:18px;
        text-align:center;
        text-justify: auto;
        font-weight: 200;	
    }
    
    .text_in_header p {
        font-family: "Agency";
        margin: 0px;
        padding:4px;	 		
        text-shadow: 0 0 10px #000;   
    }

    .bg_headerText {
        background: rgba(251, 251, 251, .2);  
    }