.five_page {	
	width: 100%;
	background-image: url('../../../img/changeCamp/Five/headerFive.jpg');
	overflow: hidden;
	position: relative;
	z-index: 10;
	height: 130vh;	
	-webkit-background-size: cover;
	background-size: cover;	
}

.five_page .chang_block {	
    position: absolute;
    left: 0;
    width: 100%;
    top: 25%;	
}
    
.bg_fiveText {
    background-color: rgb(166,199,25, 0.7);
}