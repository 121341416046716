body {
    font-family: "EtelkaMediumProRegular";
}

/** Margin **/
.m20 {
    margin: 20px;
}

.m20-0 {
    margin: 20px 0;
}

.m0-5 {
    margin: 0 5px;
}

.m-b20 {
    margin-bottom: 20px;
}

.m40-0 {
    margin:40px 0;
}

.m-r-5 {
    margin-right: 5px;
}

/** Padding **/

.p20-0{
    padding: 20px 0;
}

.p-t120 {
    padding-top: 120px;
}

.p-t80 {
    padding-top: 80px;
}

.i-center {
    align-items: center;
    justify-content: center;
}

.t-center {
    text-align: center;
}

.t-right {
    text-align: right;
}

.t-left {
    text-align: left;
}

.t-just {
    text-align: justify;
}

.t-indent {
    text-indent: 1.5em;
}

/** Colors **/

.brown-color{
    color: #ca8e47;
}

.white-color {
    color:#fff;
}

.green_color {
    color: #0c8e36;
}

.blue_color {
    color:#0070c0;
}

.orange_color {
    color:#ff9900;
}

.green-bg {    
    background: #0d7824;
}


.bg_light_grey{
    background: rgba(233, 231, 230, 0.7);
}

.light_green_color {
    color:#fff;
    font-weight: 500;
    padding: 0px 4px;
    background: rgb(30,126,52,0.7 );
    font-family: "Agency";
    letter-spacing:5px;
    text-transform: uppercase;
}

.white_color {
    background: rgb(255,255,255,0.9 );
    padding: 0 20px;
    margin: 20px 0;
    border-radius: 5px;
}

  
.light_btn_1change {
    background-color:rgb(223,59,46, 0.6);
}
    
.light_btn_2change {
    background-color: rgba(116, 17, 94, 0.6);
}
    
.light_btn_3change {
    background-color: rgb(166,199,25, 0.6);
}
    
.light_btn_4change {
    background-color: rgb(112,62,143, 0.6);
}
    
.light_btn_5change {
    background-color: rgb(28, 120, 164, 0.6);
}

.block-title{
    padding-top: 10px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing:5px;
    font-family: "Agency";
}

.page-title {	
    font-size:30px;
    text-align: center;
    color: #0c8e36;
    text-transform:uppercase;
    letter-spacing:5px;
    font-family: "Agency";
}

/** Style for 5 Camp Change  **/

.change_text {
    padding: 40px;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
}

.change_text .tittle{
    font-weight: 600;
    font-size: 37px;
    margin: 32px 0;
}

.change_text .price {
    border-radius: 5px;    
    color: #fff;
    background: #0d7824;   
    display: inline-block;   
    padding: 0 20px;
    font-size: 24px;    
}


/** **/

.slick-arrow {
	display: none !important;
}