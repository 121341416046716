.life_page {	
	width: 100%;
	background-image: url('../../../img/changeCamp/Life/headerLife.jpg');
	overflow: hidden;
	position: relative;
	z-index: 10;
	height: 130vh;	
	-webkit-background-size: cover;
	background-size: cover;	
}

.life_page .chang_block {	
    position: absolute;
    left: 0;
    width: 100%;
    top: 25%;	
}


.bg_lifeText {
	background-color: rgb(223,59,46, 0.5);
}

.map-rangers {
	width: 100%;
}
