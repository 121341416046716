
.day-tittle {
    color: #ca8e47;
}

.day-border{
    border-top: 2px solid #f4f4f4;
    border-right: 2px solid #f4f4f4;
}

.day-time {
    color: #ca8e47;
    text-align: center;
    font-size: 20px;
}