.block_about{
	width: 100%;
	/*background-image: url('../../../img/headerAbout.jpg');*/
	overflow: hidden;
	position: relative;
	z-index: 10;
	height: auto;	
	-webkit-background-size: cover;
	background-size: cover;			
}

.bg_about {
  background: rgba(147, 96, 55, 0.7);
}

.title {
	color:#fff;
	text-align:center;
	text-transform: uppercase;
}

.title_p {
	padding-left:40px;
	padding-right:40px;
	padding-top:40px;	
}

.text_in_block {
	margin-bottom: 20px;
	padding:5px;
	border-radius: 5px;	
	font-size:18px;
	text-align:justify;
	text-justify: auto;
	font-weight: 500;		
}

.text_in_block p {
	margin: 0px;
	padding:10px;	 		  
}


@media only screen and (max-width : 480px) {

    .title-about {
        font-size: 25px;
    }
}

@media only screen and (max-width : 320px) {

    .title-about {
        font-size: 25px;
    }
}