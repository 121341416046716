
.term-tittle {
    color: #ca8e47;
}

.term-border{
    border-top: 2px solid #f4f4f4;
}

.term-img {
    color: #ca8e47;
    text-align: center;
    font-size: 20px;
}

.term-img img {
    width: 60%;
}